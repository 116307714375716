// STYLE RESETS
// Adapted from http://github.com/necolas/normalize.css
// --------------------------------------------------

// Apply a natural box layout model to all elements
*, *:before, *:after {
  -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
 }

// Remove margin
body { margin: 0; }

// Display HTML5 elements in IE6-9 and FF3
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}

// Display block in IE6-9 and FF3
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

// Prevents modern browsers from displaying 'audio' without controls
audio:not([controls]) {
  display: none;
}

// Base font settings
html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
// Apply focus state
a:focus {
  @extend %tab-focus;
}

// Remove outline
a:hover,
a:active {
  outline: 0;
}

// Prevent sub and sup affecting line-height in all browsers
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}

// Normalize blockquotes
blockquote {
  margin: 0;
}

// Img border in a's and image quality
img {
  // Responsive images (ensure images don't scale beyond their parents)
  max-width: 100%; // Part 1: Set a maximum relative to the parent
  width: auto\9; // IE7-8 need help adjusting responsive images
  height: auto; // Part 2: Scale the height according to the width, otherwise you get stretching

  vertical-align: middle;
  border: 0;
  -ms-interpolation-mode: bicubic;
}

// Prevent max-width from affecting Google Maps
#map_canvas img,
.google-maps img {
  max-width: none;
}

// Consistent form font size in all browsers, margin changes, misc
button,
input,
select,
textarea {
  margin: 0;
  font-size: 100%;
  vertical-align: middle;
}

button,
input {
  *overflow: visible; // Inner spacing ie IE6/7
  line-height: normal; // FF3/4 have !important on line-height in UA stylesheet
}

button::-moz-focus-inner,
input::-moz-focus-inner { // Inner padding and border oddities in FF3/4
  padding: 0;
  border: 0;
}

button,
html input[type="button"], // Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio` and `video` controls.
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button; // Corrects inability to style clickable `input` types in iOS.
  cursor: pointer; // Improves usability and consistency of cursor style between image-type `input` and others.
}

label,
select,
button,
input[type="button"],
input[type="reset"],
input[type="submit"],
input[type="radio"],
input[type="checkbox"] {
  cursor: pointer; // Improves usability and consistency of cursor style between image-type `input` and others.
}

input[type="search"] { // Appearance in Safari/Chrome
  @include box-sizing(content-box);
  -webkit-appearance: textfield;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none; // Inner-padding issues in Chrome OSX, Safari 5
}

textarea {
  overflow: auto; // Remove vertical scrollbar in IE6-9
  vertical-align: top; // Readability and alignment cross-browser
}
