/* ==========================================================================
   Notices
   ========================================================================== */

/*
   Default notice
   ========================================================================== */

.notice {
  margin-top: 1.5em;
  padding: .5em 1em;
  text-indent: 0;
  @include font-size(16);
  background-color: lighten($black, 95);
  border: 1px solid lighten($black, 90);
  @include rounded(4px);
}

/*
   Browser upgrade notice
   ========================================================================== */

.upgrade {
  padding: 10px;
  text-align: center;
}
