@mixin reset-display {
  $container-display-table: false !global;
}

@mixin reset-layout-direction {
  $layout-direction: $default-layout-direction !global;
}

@mixin reset-all {
  @include reset-display;
  @include reset-layout-direction;
}
